import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  Text
} from 'recharts'

import './styles.scss'
import { abbreviateNumber, getLocaleCost } from '../../helpers/number'

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="qu-chart-tooltip">
        <ul className="tooltip-list">
          {payload.map((item, i) => {
            return (
              <li key={i} className="tooltip-list-item">
                <span className="name">{item.name}</span>
                <span className="value">{getLocaleCost(item.value)}</span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return null
}

export interface Props {
  className?: string
  height?: any
  color?: string
  data?: any
  label?: string
  xAngle?: number
  yAngle?: number
}

const QAreaChart = ({
  className = '',
  height = 300,
  color = '#F7A57A',
  data,
  label,
  xAngle = 0,
  yAngle = 0
}: Props) => {
  const CustomizedXAxisTick = ({ x, y, payload }) => {
    const value = !isNaN(payload.value)
      ? abbreviateNumber(payload.value)
      : payload.value
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          angle={xAngle}
          fill="#6E8590"
          className="qu-chart-axis-tick"
          width={100}
          x={0}
          y={0}
          verticalAnchor="start"
          textAnchor="middle"
        >
          {value}
        </Text>
      </g>
    )
  }

  const CustomizedYAxisTick = ({ x, y, payload }) => {
    const value = !isNaN(payload.value)
      ? abbreviateNumber(payload.value)
      : payload.value
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          angle={yAngle}
          fill="#6E8590"
          className="qu-chart-axis-tick"
          width={100}
          x={-4}
          y={0}
          verticalAnchor="middle"
          textAnchor="middle"
        >
          {value}
        </Text>
      </g>
    )
  }

  return (
    <div className={`qu-chart qu-chart-bar ${className}`}>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 40, left: 0, bottom: 0 }}
        >
          <XAxis
            dataKey="name"
            axisLine={false}
            // @ts-ignore
            tick={<CustomizedXAxisTick />}
          />
          <YAxis
            tickLine={false}
            // @ts-ignore
            tick={<CustomizedYAxisTick />}
          />
          <Tooltip
            // @ts-ignore
            content={<CustomTooltip />}
          />
          <Area
            type="monotone"
            dataKey="key"
            stroke={color}
            fill={color}
            name={label}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
export default QAreaChart
