import React from 'react'
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts'

import './styles.scss'
import { chartColors } from '../../constants/colors'
import { CustomizedLabel } from './CustomizedLabel'

export interface Props {
  className?: string
  data?: any
  colors?: object
}

const QBarChartVertical = ({ className = '', data, colors }: Props) => {
  return (
    <div className={`qu-chart qu-chart-bar ${className}`}>
      <ResponsiveContainer>
        <BarChart
          layout="vertical"
          maxBarSize={14}
          data={data}
          margin={{ top: 15, right: 0, bottom: 0, left: -10 }}
        >
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis
            type="number"
            axisLine={false}
            tickLine={false}
            tick={{ fill: '#6E8590' }}
          />
          <YAxis
            type="category"
            dataKey="name"
            width={100}
            tickLine={false}
            tick={{ fill: '#212930' }}
          />
          {/* <Tooltip */}
          {/*  // @ts-ignore */}
          {/*  content={<CustomTooltip />} */}
          {/*  cursor={{ fill: 'rgb(243 244 246 / 30%)' }} */}
          {/* /> */}
          <Bar dataKey="value" label={CustomizedLabel}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors ? colors[index] : chartColors[index]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
export default QBarChartVertical
